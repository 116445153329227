export const buildQueryString = (parameters: Record<string, any>): string => {
  const queryParts: string[] = [];
  for (const key of Object.keys(parameters)) {
    const value = parameters[key];
    if (Array.isArray(value)) {
      for (const item of value) queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
    } else if (value !== undefined) {
      queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }
  return queryParts.join('&');
};
