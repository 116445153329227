import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { loginTokens, useMe } from 'helpers/api';
import { Me } from 'helpers/types';
import { removeTokens, saveTokens } from 'helpers/tokens';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

interface UserContextType {
  isLoadingUser: boolean;
  login: (values: { email: string; password: string }) => Promise<void>;
  logout: () => void;
  user?: Me;
}

const initialState = {
  isLoadingUser: true,
  // eslint-disable-next-line unicorn/no-useless-undefined
  login: async () => undefined,
  logout: async () => {
    // init
  },
  user: undefined,
};

const UserContext = createContext<UserContextType>(initialState);

interface Properties {
  children: ReactNode;
}

export const UserProvider = ({ children }: Properties) => {
  const queryClient = useQueryClient();
  const { data: user, isLoading, refetch } = useMe();
  const router = useRouter();

  const login = useCallback(
    async (data: { email: string; password: string }) => {
      const response = await loginTokens(data);
      saveTokens(response.token, response.refresh_token);
      refetch();
    },
    [refetch]
  );

  const logout = useCallback(() => {
    removeTokens();
    queryClient.resetQueries();

    const path = router.asPath;
    path === '/' ? router.reload() : router.push('/');
  }, [queryClient, router]);

  const value = useMemo(() => ({ isLoadingUser: isLoading, login, logout, user }), [isLoading, login, logout, user]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
