import {
  BuildingOverview,
  CandidateOverview,
  CarOverview,
  Me,
  NewBuilding,
  NewCar,
  TokenResponse,
  CandidateDetail,
  BuildingDetail,
  CarDetail,
  RoomDetail,
  UpdateCarData,
  UpdateRoomData,
  Statistics,
  ApiResetPassword,
  NewUser,
  EditRoom,
  UpdateUser,
  BikeStatistics,
  Snapshot,
  DateRange,
  Status,
  AuditLog,
} from 'helpers/types';
import { getTokens } from 'helpers/tokens';
import { useQuery } from 'react-query';
import { buildQueryString } from './buildQueryString';

export const USE_ME_QUERY_KEY = 'useMe';

const ITEMS_PER_PAGE = 5000; // NOTE: when there are more than 1000 items this only shows the first 1000
const FIFTY_MINUTES = 1000 * 60 * 50;

export const addCar = (car: NewCar) => fetchApi<CarOverview>('/cars', 'POST', car);
export const deleteCar = (carId: string) => fetchApi<number>(`/cars/${carId}`, 'DELETE');
export const editCar = (carId: string, car: Partial<NewCar>) => fetchApi<number>(`/cars/${carId}`, 'PUT', car);

export const changeCarStatus = ({ carId, status }: { carId: string; status: Status }) =>
  fetchApi<number>(`/cars/${carId}/change_status/${status}`, 'PUT');

export const updateCar = (carId: string, data: UpdateCarData) => fetchApi<CarDetail>(`/cars/${carId}`, 'PUT', data);

export const removeCandidateFromCar = (carId: string, candidateId: string, droveUntil?: string) =>
  fetchApi<CarDetail>(`/cars/${carId}/remove_passenger`, 'POST', { passenger: candidateId, droveUntil });

export const changeDriversLicence = (candidateId: string, licencedDriver: boolean | null) =>
  fetchApi<CarDetail>(`/candidates/${candidateId}`, 'PUT', { licencedDriver });

export const replaceDriver = ({
  carId,
  driverId,
  droveUntil,
  since,
}: {
  carId: string;
  driverId: number;
  droveUntil?: string;
  since?: string;
}) => fetchApi<CarDetail>(`/cars/${carId}/replace_driver`, 'POST', { newDriver: driverId, droveUntil, since });

export const addCandidateToCar = ({
  carId,
  passenger,
  drives,
  since,
  additionForPrivateUse,
}: {
  carId: string;
  passenger?: string;
  drives: boolean;
  since?: string;
  additionForPrivateUse?: boolean;
}) => fetchApi<CarDetail>(`/cars/${carId}/add_passenger`, 'POST', { passenger, drives, since, additionForPrivateUse });

export const addBuilding = (building: NewBuilding) => fetchApi<BuildingDetail>('/buildings', 'POST', building);
export const removeBuilding = (buildingId: string) => fetchApi<number>(`/buildings/${buildingId}`, 'DELETE');
export const editBuilding = (buildingId: string, building: Partial<NewBuilding>) =>
  fetchApi<NewBuilding>(`/buildings/${buildingId}`, 'PUT', building, true);

export const editRoom = (roomId: string, room: Partial<EditRoom>) =>
  fetchApi<NewBuilding>(`/rooms/${roomId}`, 'PUT', room);

export const addCandidateToRoom = (roomId: string, candidateId: string) =>
  fetchApi<RoomDetail>(`/rooms/${roomId}/add_guest`, 'POST', { guest: candidateId });

export const removeCandidateFromRoom = (roomId: string, candidateId: string) =>
  fetchApi<RoomDetail>(`/rooms/${roomId}/remove_guest`, 'POST', { guest: candidateId });

export const updateReservedBedsInRoom = (roomId: string, data: UpdateRoomData) =>
  fetchApi<RoomDetail>(`/rooms/${roomId}`, 'PUT', data);

export const useBuildings = () =>
  useQuery(['useBuildings'], () =>
    fetchApi<BuildingOverview[]>(`/buildings?${getQueryStringForOptions({ itemsPerPage: ITEMS_PER_PAGE })}`)
  );
export const useBuilding = (buildingId?: string, enabled?: boolean) =>
  useQuery(['useBuilding', { buildingId }], () => fetchApi<BuildingDetail>(`/buildings/${buildingId}`), {
    enabled: enabled ?? !!buildingId,
  });

export const useRoom = (roomId: string) =>
  useQuery(['useRoom', { roomId }], () => fetchApi<RoomDetail>(`/rooms/${roomId}`), {
    enabled: !!roomId,
  });

export const useCandidates = (options?: { ids?: number[] }, useQueryOptions?: { enabled: boolean }) =>
  useQuery(
    ['useCandidates', { ids: options?.ids }],
    async () => {
      const data = await fetchApi<CandidateOverview[]>(
        `/candidates?${getQueryStringForOptions({ ...options, itemsPerPage: ITEMS_PER_PAGE })}`
      );

      if (options?.ids) {
        return data.filter(({ id }) => options.ids?.includes(id));
      }
      return data.sort((a, b) => (a.state || '' < (b.state || '') ? -1 : 1));
    },
    { enabled: useQueryOptions?.enabled || true }
  );

export const useCandidate = (candidateId?: string) =>
  useQuery(['useCandidate', { candidateId }], () => fetchApi<CandidateDetail>(`/candidates/${candidateId}`), {
    enabled: !!candidateId,
  });

export const useStatistics = () => useQuery(['useStatistics'], () => fetchApi<Statistics>(`/statistics`));

export const useSnapshots = (period?: DateRange) => {
  const itemsPerPage = getNumberOfItemsPerPage(period);

  return useQuery(['useSnapshots', period], async () =>
    fetchApi<Snapshot[]>(
      `/snapshots?${getQueryStringForOptions({
        'createdAt[after]': period ? period[0] : '',
        'createdAt[before]': period ? period[1] : '',
        itemsPerPage: itemsPerPage || 0,
      })}`
    )
  );
};

export const useBikeStatistics = () =>
  useQuery(['useBikeStatistics'], () => fetchApi<BikeStatistics>(`/bike_statistics`));

export const updateBikeCount = (bikeCount: number) => fetchApi(`/bike_count?bikeCount=${bikeCount}`, 'POST');

export const updateTransportationType = (candidateId: string, transportationType: 'car' | 'bike' | 'own') =>
  fetchApi(`/candidates/${candidateId}`, 'PUT', { transportationType });

export const useCars = (options?: { ids?: string[] }, useQueryOptions?: { enabled: boolean }) =>
  useQuery(
    ['useCars', { ids: options?.ids }],
    async () => {
      const data = await fetchApi<CarOverview[]>(
        `/cars?${getQueryStringForOptions({ ...options, itemsPerPage: ITEMS_PER_PAGE })}`
      );

      if (options?.ids) {
        return data.filter(({ id }) => options.ids?.includes(id?.toString()));
      }
      return data;
    },
    { enabled: useQueryOptions?.enabled || true }
  );

export const useCar = (carId?: string) =>
  useQuery(['useCar', { carId }], () => fetchApi<CarDetail>(`/cars/${carId}`), { enabled: !!carId });

export const useMe = () =>
  useQuery(USE_ME_QUERY_KEY, () => fetchApi<Me>('/users/me'), {
    retry: false,
    staleTime: FIFTY_MINUTES,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export interface AuditLogQueryParameters {
  changedBy?: string | string[];
  candidate?: string | string[];
  action?: string | string[];
  room?: string | string[];
  building?: string | string[];
  car?: string | string[];
  'createdAt[before]'?: string;
  'createdAt[strictly_before]'?: string;
  'createdAt[after]'?: string;
  'createdAt[strictly_after]'?: string;
  'changeInEffectAt[before]'?: string;
  'changeInEffectAt[strictly_before]'?: string;
  'changeInEffectAt[after]'?: string;
  'changeInEffectAt[strictly_after]'?: string;
  page?: number;
  itemsPerPage?: number;
}

export const useAuditLogs = (queryParameters: AuditLogQueryParameters = {}) => {
  const queryString = buildQueryString(queryParameters);

  return useQuery(['useAuditLogs', queryParameters], () =>
    fetchApi<AuditLog[]>(`/audit_logs${queryString ? `?${queryString}` : ''}`)
  );
};

export const registerUser = (newUserData: NewUser) => fetchApi<TokenResponse>('/users/register', 'POST', newUserData);

export const requestResetPassword = (email: string) =>
  fetchApi<TokenResponse>('/users/reset_password/request', 'POST', { email });

export const confirmResetPassword = (resetPasswordData: ApiResetPassword) =>
  fetchApi<TokenResponse>('/users/reset_password/reset', 'POST', resetPasswordData);

export const updateUserCredentials = (userCredentialsData: UpdateUser) =>
  fetchApi<TokenResponse>('/users/update_credentials', 'PUT', userCredentialsData);

export const loginTokens = ({ email, password }: { email: string; password: string }) =>
  fetchApi<TokenResponse>('/jwt/token', 'POST', { email, password });

export const refreshTokens = ({ refresh_token }: { refresh_token: string }) =>
  fetchApi<TokenResponse>('/jwt/token/refresh', 'POST', { refresh_token });

interface ResponseWithMaybeData<T> extends Response {
  data?: T;
}

const fetchApi = async <T>(
  endpoint: string,
  method: RequestInit['method'] = 'GET',
  body?: unknown,
  hydra?: boolean
) => {
  const tokens = getTokens();
  const response: ResponseWithMaybeData<T> = await fetch(`${process.env.NEXT_PUBLIC_API_URL}${endpoint}`, {
    method,
    ...(body ? { body: JSON.stringify(body) } : {}),
    headers: {
      ...(!!tokens?.accessToken && !endpoint.startsWith('/jwt/token')
        ? { Authorization: `Bearer ${tokens.accessToken}` }
        : {}),
      Accept: 'application/json',
      'Content-type': hydra ? 'application/ld+json' : 'application/json',
    },
  });

  const json: T = await response.json();

  if (response.ok) {
    return json;
  } else {
    response.data = json;
    throw new ApiError<T>(response);
  }
};

function getQueryStringForOptions(options?: Record<string, string | number | string[] | number[]>) {
  if (!options) {
    return '';
  }

  const parameters = new URLSearchParams();

  for (const [key, value] of Object.entries(options || {})) {
    if (value) {
      parameters.append(key, value.toString());
    }
  }

  return parameters.toString();
}

export class ApiError<T> extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  public readonly body: T | undefined;

  constructor(response: ResponseWithMaybeData<T>) {
    super(`${response.status} ${response.statusText}`);

    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.data;
  }
}

// get number of days between two dates
export const getDaysBetween = (date1: Date, date2: Date) =>
  Math.floor((date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)) + 1;

function getNumberOfItemsPerPage(period?: DateRange) {
  if (!period) return;
  return getDaysBetween(new Date(period[1]), new Date(new Date(period[0])));
}
