import './global.css';

import { ApiError, refreshTokens, USE_ME_QUERY_KEY } from 'helpers/api';
import { AppProps } from 'next/app';
import { captureException } from 'helpers/captureException';
import { getTokens, removeTokens, saveTokens } from 'helpers/tokens';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { useMemo } from 'react';
import { UserProvider } from 'providers/UserProvider';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount, error) => {
              if (failureCount > 0) {
                return false;
              }

              return error instanceof ApiError && error.status === 401;
            },
          },
        },
        queryCache: new QueryCache({
          onError: async (error) => {
            const isUnauthorizedError = error instanceof ApiError && error.status === 401;
            const tokens = getTokens();

            if (isUnauthorizedError && tokens?.refreshToken) {
              try {
                const response = await refreshTokens({ refresh_token: tokens.refreshToken });

                if (response.token && response.refresh_token) {
                  saveTokens(response.token, response.refresh_token);
                  await queryClient.refetchQueries([USE_ME_QUERY_KEY]);
                } else {
                  removeTokens();
                  queryClient.resetQueries();
                }
              } catch {
                removeTokens();
                queryClient.resetQueries();
              }
            } else if (!isUnauthorizedError) {
              captureException(error);
            }
          },
        }),
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <Component {...pageProps} />
      </UserProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
